import { Instagram, Twitter, Facebook, Globe } from "react-feather";

export default function SocialMediaList({ social }) {
  const icons = {
    facebook: (
      <Facebook
        size={30}
        strokeWidth={1}
        className="text-blue-800 fill-blue-800"
      />
    ),
    instagram: (
      <Instagram size={30} strokeWidth={1} className="text-fuchsia-700" />
    ),
    twitter: (
      <Twitter
        size={30}
        strokeWidth={1}
        className="text-blue-500 fill-blue-500"
      />
    ),
    website: <Globe size={30} strokeWidth={1} className="text-gray-500" />,
  };

  let dis_icons = [];

  Object.keys(social)
    .filter((x) => social[x] !== null)
    .map((key, index) => dis_icons.push({ url: social[key], name: key }));

  return (
    <ul className="flex flex-row gap-4 py-5">
      {dis_icons.map((social) => (
        <li key={social.name}>
          <a href={social.url} target="_blank" rel="nofollow">
            {icons[social.name]}
          </a>
        </li>
      ))}
    </ul>
  );
}
