import PeopleSection from "./peoplesection";
import BookSection from "./booksection";
import {
  split_sequence,
  get_containing,
  normalize_graphql_data,
} from "./utils";
import SocialMediaList from "./socialmedialist";
// import Breadcrumbs from "./breadcrumbs";
import categories from "../categories";
import ShareWhatsButton from "./sharewhats";
import { Link } from "gatsby";
import SEO from "../components/seo";
// import { useLocation } from "@reach/router";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

function PersonDetail({ people, other_people, other_books, location }) {
  let person = people[0];
  let books = person.books;

  let attributes = split_sequence(person.person_attributes);
  let social_media = person.social_attributes;
  let random_people = normalize_graphql_data(other_people);

  return (
    <div className="px-8 md:px-32 gap-10">
      <SEO
        title={"Livros recomendados por " + person.person_name}
        imagesharp={person.person_image}
        description={
          "Os melhores livros recomendados por " +
          person.person_name +
          " | Descubra os livros recomendados pelas pessoas mais bem sucedidas do mundo."
        }
      />
      <div className="grid grid-col-1 place-items-center lg:mt-10 lg:gap-5">
        <div className="grid grid-rows place-items-center gap-5 max-w-2xl mb-10">
          <div className="max-w-xs p-10 lg:p-2">
            <GatsbyImage
              image={getImage(person.person_image)}
              alt={person.person_name}
            />
            {/* <img
              className="shadow-lg transition hover:transform hover:duration-150 hover:-translate-y-2 hover:shadow-lg"
              src={person.person_image}
            /> */}
          </div>
          <h1 className="text-2xl lg:text-4xl font-semibold text-blue-800 text-center">
            Livros recomendados por{" "}
            <span className="inline-block capitalize">
              {person.person_name}
            </span>
          </h1>
          <h2 className="text-xl lg:text-2xl font-light text-center">
            {person.person_description}
          </h2>
          <div className="flex flex-row flex-wrap gap-2">
            {attributes.map((att) => (
              <Link to={"/setor/" + categories["url_person"][att]} key={att}>
                <div className="px-3 py-2 bg-blue-100 rounded-sm text-xs text-blue-800 font-light hover:bg-blue-200">
                  {att}
                </div>
              </Link>
            ))}
          </div>

          <SocialMediaList social={social_media} />
          <div>
            <ShareWhatsButton person={person} books={books} />
          </div>
        </div>
      </div>
      <div className="mb-10">
        <BookSection
          books={books}
          cta={"Livros recomendados por " + person.person_name}
        />
      </div>

      <div className="mb-10">
        <PeopleSection
          people={random_people}
          cta="Outras pessoas também viram"
        />
      </div>
    </div>
  );
}

export default PersonDetail;
