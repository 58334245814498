export default function ShareWhatsButton({ person, books, size }) {
  //if size is not set

  let default_class = "px-2 py-2 bg-gray-800 text-white rounded-lg";

  if (size == "small") {
    default_class = "px-2 py-2 bg-gray-800 text-white text-sm rounded-lg";
  }

  return (
    <button
      className="bg-green-400 px-2 py-4 text-white rounded-lg text-xl shadow-lg "
      onClick={() =>
        window.open(
          `https://api.whatsapp.com/send?text=Livros%20recomendados%20por%20  ${person.person_name}%0A%0A1.${books[0].name}%0A2.${books[1].name}%0A%0APara%20mais%3A${window.location.href}`
        )
      }
    >
      enviar no whatsapp
    </button>
  );
}
