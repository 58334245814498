import { Link, graphql } from "gatsby";
import PersonDetail from "../../components/persondetail";
import Layout from "../../components/layout";

const PersonDetailPage = ({ data }) => {
  return (
    <Layout pageTitle="Home Page">
      <PersonDetail
        people={data.target.nodes}
        other_people={data.other_people.edges}
      />
    </Layout>
  );
};

export const query = graphql`
  query ($person_code: String) {
    target: allPeepsJson(filter: { person_code: { eq: $person_code } }) {
      nodes {
        ...PeepsJsonFragmentComplete
      }
    }
    other_people: allPeepsJson(limit: 12, sort: { fields: person_ranking }) {
      edges {
        node {
          ...PeepsJsonFragment
        }
      }
    }
    other_books: allBeeksJson(
      limit: 12
      sort: { fields: recommended_volume, order: DESC }
    ) {
      edges {
        node {
          ...BeeksJsonFragment
        }
      }
    }
  }
`;

export default PersonDetailPage;
